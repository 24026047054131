.App {
  text-align: center;
  font-family: carrotflower, sans-serif;
  font-weight: 400;
  font-style: normal;
  display: flex;
  width: 100vw;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  ;
}

.tree {
  height: 200px;
}