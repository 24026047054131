@keyframes enlarge {
    0% {
        transform: scale(1.0);
    }

    100% {
        transform: scale(1.05);
    }
}

.plank-frame {
    background-repeat: repeat-y;
    background-position: center;
    border: 1px solid #75533A;
    box-shadow: 0px 1px 1px 1px #1E1E1E;
    padding: 10px 0px;
    max-width: 92vw;
    margin: 30px auto;
    z-index: 3;
}

.plank-frame>p {
    margin: 0 15px;
    display: flex;
    justify-content: center;
}

.plank-container>a {
    text-decoration: none;
    color: transparent;
    text-shadow: 1px 1px 1px #f4e975, 0 0 0 #000, 1px 1px 0px #f4e975;
    font-weight: 600;
    font-size: 24px;
 }

 .plank-container:hover {
    animation: enlarge 0.5s infinite alternate;
 }