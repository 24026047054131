.post-wrapper {
    margin-top: 50px;
    min-height: calc(100vh - 50px);
}

.vertical-post {
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    width: 45px;
    border: 1px solid #75533A;
    box-shadow: 0px 1px 2px 1px #1E1E1E;
    z-index: 1;
}


.vertical-post-sign {
    font-size: 48px;
    position: relative;
    z-index: 2;
    box-sizing: border-box;
    padding: 30px 40px;
    width: fit-content;
    max-width: 92vw; 
    margin: 0 auto;
    background: #BB855C;
    background-position: center;
    border: 1px solid #75533A;
    text-shadow: 1px 1px 1px #f4e975, 0 0 0 #000, 1px 1px 0px #f4e975;
    color: transparent;
    border-radius: 50%;
    box-shadow: 0px 1px 2px 1px #1E1E1E;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.vertical-post-sign>h1 {
    font-size: 48px;
    margin: 5px auto;
}

.vertical-post-sign>h2 {
    font-size: 36px;
    margin: 0 auto;
    text-align: center;
}

.plank-wrapper {
    position: relative;
    z-index: 2;
    margin-bottom: 100px;
}

@media (max-width: 340px) {
    .vertical-post-sign>h1 {
        font-size: 36px;
    }
    
    .vertical-post-sign>h2 {
        font-size: 30px;
    }
    
}